import {environment} from '../../../../environments/environment';
import {Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, OnDestroy} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {TranslateService} from '@ngx-translate/core';
import {NotificationsService} from 'angular2-notifications';
import {Course} from '../../courses/model';
import {Resource, ResourceType} from '../model';
import {ScormUnit} from '../../scorm/unit/model';
import {forkJoin, Subscription} from 'rxjs';
import {CourseService} from '../../courses/course.service';
import {ResourceService} from '../resource.service';
import {PathService} from '../../paths/path.service';
import {ScormCourse} from '../../scorm/course/model';
import {AppEnvService} from '../../../config/env/service';
import {CourseSync, Lesson} from '../../synchronous/course/model';

@Component({
  selector: 'app-course-resources',
  templateUrl: './course-resources.component.html',
  styleUrls: ['./course-resources.component.scss', '../../scorm/course/course-scorm.component.scss']
})
export class CourseResourcesComponent implements OnInit, OnDestroy {
  @Input()
  set course(c: Course) {
    this._course = c;
    this.getCourseData();
  }

  _course: Course;
  courseResources: Resource[];

  @Output()
  unitSelected = new EventEmitter<ScormUnit>();
  @Output()
  lessonSelected = new EventEmitter<Lesson>();
  @Output()
  resourceSelected = new EventEmitter<Resource>();

  resourceType = ResourceType;
  modalRef: BsModalRef;
  viewerUrl: string;
  openedUnit: ScormUnit;
  openedLesson: Lesson;
  subscriptions: Subscription[] = [];
  courseDataSubscriptions: Subscription[] = [];
  errorMessage: string;
  loading: boolean;
  subscription: Subscription[] = [];

  constructor(
    private courseService: CourseService,
    private resourceService: ResourceService,
    private translateService: TranslateService,
    private notificationService: NotificationsService,
    private pathService: PathService,
    private envService: AppEnvService,
    private chageDetector: ChangeDetectorRef) {
    this.viewerUrl = this.envService.config().scorm.viewerUrl;
    // this.pathService.unitHasBeenClosed.subscribe(u => this.getCourseData());
  }

  ngOnInit() {
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  private courseDataMapper = course => {
    this._course = course;
    this._course.imageUrl = this._course.image && this.courseService.getCourseImageUrl(this._course.image);
    if (!this.courseResources) {
      return;
    } else {
      this.courseResources.sort((a, b) => a.order - b.order);
      for (const r of this.courseResources) {
        if (r.type === ResourceType.DOCUMENT && r.metadata && r.metadata.url) {
          r.url = r.metadata.url;
        }
        if (r.locked) {
          switch (r.lockReason) {
            case 'PREVIOUS_RESOURCE_COMPLETION_REQUIRED':
            default:
              /*this.translateService.get('Previous resource completion required').subscribe(
                t => r.lockReason = t
              );*/
              break;
          }
        }
      }
      this.chageDetector.detectChanges();
    }
  }

  private getCourseData() {
    if (!this._course) {
      return;
    }
    this.loading = true;
    this.subscriptions.push(
      this.courseService.getCourseByPathId(this._course.id, this._course.id_path).subscribe(c => {
        this.loading = false;
        this.courseResources = c.resources;
        this.courseDataMapper(c);
    }));
  }

  doOpenUnit(unit: ScormUnit) {
    this.unitSelected.emit(unit);
  }

  getWarningMessage(lockReason: string) {
    let lockedReason = 'This unit is locked';
    switch (lockReason) {
    case 'PREVIOUS_RESOURCE_COMPLETION_REQUIRED_ON_SAME_COURSE':
      lockedReason = 'Can\'t validate this course. Previous resource already completed in other course';
      break;
    case 'PREVIOUS_RESOURCE_COMPLETION_REQUIRED':
      lockedReason = 'To use this resource you must first complete the previous one';
      break;
    default:
      break;
    }
    return lockedReason;
  }

  doOpenLesson(lesson: Lesson) {
    this.lessonSelected.emit(lesson);
  }

  doOpenResource(resource: Resource) {
    if (resource.locked) {
      forkJoin(
        this.translateService.get('Warning'),
        this.translateService.get(this.getWarningMessage(resource.lockReason))

      ).subscribe(t => this.notificationService.warn(t[0], t[1], {timeOut: 3000}));
    } else {
      this.resourceSelected.emit(resource);
    }
  }

  setResourceData = (resourceData: ScormCourse) => {
    this._course.resources
      .filter(r => r.id === resourceData.resourceId)
      .map(r => Object.assign(r.metadata, resourceData));
  }

  setResourceSyncData = (resourceData: CourseSync) => {
    this._course.resources
      .filter(r => r.id === resourceData.resourceId)
      .map(r => Object.assign(r.metadata, resourceData));
  }
}
