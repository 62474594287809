import {Action} from "redux";
import {LmsResource} from "@shared/app/lms/model";
import {LmsResourcesActions, ResourcesAction} from "@shared/app/lms/resources/store/actions";

export interface LmsResourceState {
  openError: any;
  opening: boolean;
  opened: LmsResource;
}

export interface ResourcesState {
  current: LmsResourceState;
}

const INITIAL_STATE: ResourcesState = {
  current: undefined,
};


export const resourcesReducer = () => (state: ResourcesState = INITIAL_STATE, action: Action): ResourcesState => {
  switch (action.type) {

    case LmsResourcesActions.RESOURCE_OPENING:
      return {
        ...state,
        current: {...state.current, opening: true}
      };

    case LmsResourcesActions.RESOURCE_OPEN_FAILED:
      return {
        ...state,
        current: {...state.current, opening: false, openError: {...(action as ResourcesAction<any>).payload}},
      };

    case LmsResourcesActions.RESOURCE_OPENED:
      const resource = (action as ResourcesAction<LmsResource>).payload;
      return {
        ...state,
        current: {...state.current, opening: false, opened: resource}
      };

  }

  return state;
}
