import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {forkJoin, Observable, of} from 'rxjs';
import {Subject} from 'rxjs';
import {DomSanitizer, SafeStyle} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import 'rxjs';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {dia} from 'jointjs';
import CellView = dia.CellView;
import Cell = dia.Cell;
import {Subscription} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {PathService} from '../paths/path.service';
import {UserService} from '../../user/service';
import {Path} from '../paths/model';
import {AppEnvService} from '../../config/env/service';
import {Course} from './model';
import {ThemeArea} from '../themeAreas/model';
import {User} from '../../user/model';
import {Stats, StatsCounters} from '../../user/stats/model';
import {Session} from '@shared/app/lms/synchronous/course/model';

@Injectable()
export class CourseService {

  labels: Object;
  modalRef: BsModalRef;
  user: User;
  subscriptions: Subscription[] = [];

  // Observable string sources
  private updatePathInstance = new Subject<any>();
  // Observable string streams
  updatePath$ = this.updatePathInstance.asObservable();

  constructor(private http: HttpClient, private sanitizer: DomSanitizer, private translateService: TranslateService, private envService: AppEnvService, private pathService: PathService, private modalService: BsModalService, private userService: UserService) {
    this.labels = {};
    forkJoin(
      this.translateService.get('started'),
      this.translateService.get('completed'),
      this.translateService.get('not started'),
      this.translateService.get('failed')
    ).subscribe(t => {
      this.labels['incomplete'] = t[0];
      this.labels['completed'] = t[1];
      this.labels['notattempted'] = t[2];
      this.labels['failed'] = t[3];
    });
    this.userService.details().subscribe(u => this.user = u);

  }

  /**
   * Solo percorsi a cui sono iscritto
   */
  getMyPaths(areaId?: number): Observable<Path[]> {
    const url = `${this.envService.config().api.courses}/me${areaId ? `?themeId:${areaId}` : ''}`;
    return this.http.get<Path[]>(url).pipe(map(paths => {
      for (const path of paths) {
        path.imageUrl = this.getPathImageUrl(path.image);
        if (path.state !== undefined) {
          if (path.state.toLowerCase() === 'completed') {
            path.statusView = 'complete';
            path.statusLabel = this.labels['completed'];
          } else if (path.state.toLowerCase() === 'not attempted') {
            path.statusView = 'notattempted';
            path.statusLabel = this.labels['notattempted'];
          } else if (path.state.toLowerCase() === 'incomplete') {
            path.statusView = 'incomplete';
            path.statusLabel = this.labels['incomplete'];
          } else {
            path.statusView = path.state.toLowerCase();
          }
        } else {
          path.statusView = 'notattempted';
          path.statusLabel = this.labels['notattempted'];
        }
      }
      return paths;
    }));
  }

  enrollPath(path: Path): Observable<boolean> {
    const response = new Subject();
    const url = `${this.envService.config().api.paths}/${path.id.toString()}/enroll`;
    return this.http.post(url, {}).pipe(map(
      data => data['enrolled'] === 1,
      err => false
    ));
  }

  enrollSession(session: Session): Observable<boolean> {
    const response = new Subject();
    const url = `${this.envService.config().api.enrollSession}/${session.id.toString()}`;
    return this.http.post(url, {}).pipe(map(
      data => true,
      err => err.error
    ));
  }

  removeFromSession(session: Session): Observable<boolean> {
    const response = new Subject();
    const url = `${this.envService.config().api.enrollSession}/${session.id.toString()}`;
    return this.http.put(url, {}).pipe(map(
      data => true,
      err => err.error
    ));
  }


  getCourses(filter: any): Observable<Course[]> {
    let url = this.envService.config().api.courses;
    if (filter !== null && filter.mandatory !== null) {
      url += '?filter=mandatory:' + filter.mandatory;
    }
    return this.http.get<Course[]>(url).pipe(map(courses => {
      for (const c of courses) {
        c.imageUrl = this.getCourseImageUrl(c.image);
      }
      return courses;
    }));
  }

  getCourseCover(courseId: number): Observable<any> {
    const response = new Subject();
    let url = this.envService.config().api.courseCover;
    url = url.replace('{id}', courseId.toString());
    this.http.get(url, {responseType: 'blob'})
      .subscribe(
        data => response.next(data),
        error => response.next(null)
      );
    return response;
  }

  getCourse(courseId: number): Observable<Course> {
    const url = `${this.envService.config().api.courses}/${courseId.toString()}`;
    return this.http.get<Course>(url).pipe(map(c => {
      if (c.resources !== undefined) {
        for (const r of c.resources) {
          if (r.state !== undefined) {
            if (r.state.toLowerCase() === 'completed') {
              r.statusView = 'complete';
            } else if (r.state.toLowerCase() === 'not attempted') {
              r.statusView = 'notattempted';
            } else {
              r.statusView = r.state.toLowerCase();
            }
          } else {
            r.statusView = 'notattempted';
          }
        }
      }
      return c;
    }));
  }

  getCourseByPathId(courseId: number, pathId: number): Observable<Course> {
    const url = `${this.envService.config().api.courseByPathId.replace('{idPath}', pathId.toString()).replace('{idCourse}', courseId.toString())}`;
    return this.http.get<Course>(url).pipe(map(c => {
      if (c.resources !== undefined) {
        for (const r of c.resources) {
          if (r.state !== undefined) {
            if (r.state.toLowerCase() === 'completed') {
              r.statusView = 'complete';
            } else if (r.state.toLowerCase() === 'not attempted') {
              r.statusView = 'notattempted';
            } else {
              r.statusView = r.state.toLowerCase();
            }
          } else {
            r.statusView = 'notattempted';
          }
        }
      }
      return c;
    }));
  }

  getCourseImageUrl(imageId: string): any {
    if (imageId !== undefined && imageId !== null && imageId !== '') {
      return this.sanitizer.bypassSecurityTrustUrl(this.envService.config().api.assets + imageId);
    } else {
      return this.sanitizer.bypassSecurityTrustStyle('url(assets/img/path-cover-default.jpg)');
    }
  }

  getPathImageUrl(url: string): SafeStyle {
    if (url !== undefined && url !== null && url !== '') {
      return this.sanitizer.bypassSecurityTrustStyle('url(' + this.escapeQuote(url) + ')');
    } else {
      return this.sanitizer.bypassSecurityTrustStyle('url(assets/img/path-cover-default.jpg)');
    }
  }

  getAreaImageUrl(url: string): SafeStyle {
    if (url !== undefined && url !== null && url !== '') {
      return this.sanitizer.bypassSecurityTrustStyle('url(' + this.escapeQuote(url) + ')');
    } else {
      return this.sanitizer.bypassSecurityTrustStyle('url(assets/img/area-cover-default.jpg)');
    }
  }

  getAllAreas(): Observable<ThemeArea[]> {
    return this.http.get<ThemeArea[]>(this.envService.config().api.themeAreas).pipe(map(areas => {
      if (areas !== undefined) {
        for (const a of areas) {
          a.image = this.getAreaImageUrl(a.imageUrl);
        }
      }
      return areas;
    }));
  }

  getAreaPaths(a: ThemeArea): Observable<Path[]> {
    return this.http.get<Path[]>(this.envService.config().api.paths + '?filter=themeId:' + a.id).pipe(map(paths => {
      for (const p of paths) {
        p.imageUrl = this.getPathImageUrl(p.image);
      }
      return paths;
    }));
  }

  getPathsByAreaId(areaId: number): Observable<Path[]> {
    return this.http.get<Path[]>(this.envService.config().api.paths + '?filter=themeId:' + areaId).pipe(map(paths => {
      for (const p of paths) {
        p.imageUrl = this.getPathImageUrl(p.image);
      }
      return paths;
    }));
  }

  private escapeQuote(s: string): string {
    return encodeURI(s).replace('\'', '\\\'');
  }

  private getCellId(cell: CellView): string {
    return cell && cell.model as Cell | CellView && cell.model.id ? cell.model.id.toString() : null;
  }


}
