
<div class="page-err page-template-thin">
    <div class="text-center">
        <div class="err-status">
             <h1>
             	<span class="err-status-parenthesis">&#123;</span>
             	<span class="err-status-text"><i class="err-status-icon fa fa-frown-o text-danger"></i> Error {{errorCode}} </span>
             	<span class="err-status-parenthesis">&#125;</span>
             </h1>
        </div>
        <div class="err-message">
            <span *ngIf="errorCode == '401'" translate>Session expired</span>
            <span *ngIf="errorCode == '403'" translate>You are not authorized to access this resource</span>
        </div>
        <div class="err-body">
        	<h3 translate>{{errorDetail}}</h3>
            <a [routerLink]="['/login']" class="btn btn-lg btn-goback" *ngIf="errorCode == '401' || errorCode == '403' || errorCode == '406' || errorCode == '502'">
                <span class="fa fa-dashboard"></span>
                <span class="space"></span>
                <span translate>go to login</span>
            </a>
          <a [routerLink]="['/dashboard']" class="btn btn-lg btn-goback" *ngIf="errorCode == '403'">
            <span class="fa fa-dashboard"></span>
            <span class="space"></span>
            <span translate>go to dashboard</span>

          </a>
        </div>
    </div>
</div>
