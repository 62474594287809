
export class CourseReport {

  id_catalog: number;
  id_path: number;
  id_course: number;
  catalog_title: string;
  path_title: string;
  course_title: string;
  state: string;
  date_completed: Date;
  totalDuration: number;
  userCatalogAssociation: boolean;
  hasCertificateEntity: boolean;
}
