import {Injectable} from '@angular/core';
import {AppEnv, EnvironmentDetails} from './model';
import {environment} from '../../../environments/environment';
import {LanguageCode} from '../../i18n/model';
import {Observable} from 'rxjs';
import {DeviceDetectorService} from 'ngx-device-detector';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';

export interface AppEnvConfig extends AppEnv {
  production?: boolean;
  authentication?: {
    oauth: {
      clientId: string
      scope: string
    }
  };
  env?: {
    showLanguageSelection: boolean,
    ivassEnable: boolean,
    resourcesPath: string,
    adminImpersonateExternalUrl: string,
    dignostic: string
  };
  i18n?: {
    defaultLanguageCode: string,
    langCodeSelection: string[],
  };
  theme?: {
    defaultTheme: string,
    mappedThemes: {}
    views: {
      catalog: {
        byThemeArea: string[],
      },
      courses: {
        byThemeArea: string[],
      }
    }
  };
  api?: {
    checkToken: string,
    login: string,
    impersonate: {
      login: string,
      logout: string,
    },
    refreshTokenUrl: string,
    hierarchies: {
      list: string
    },
    themeAreas: string,
    lms: {
      admin: string,
      paths: string,
    },
    user: {
      recoverPassword: string;
      acceptPrivacy: string;
      search: string;
      details: string,
      profileImage: string,
      stats: string,
      preferences: string,
      changePassword: string,
      report: string
    },
    fields: {
      me: string
    },
    tracking: string,
    cas: {
      providers: string
    },
    theme: {
      custom: string
    },
    paths: string,
    courses: string,
    courseByPathId: string,
    resources: string,
    certificates: string,
    used: string,
    courseCover: string,
    assets: string,
    external: {
      courses: string
    },
    vdcLink: string
    activeSessionsLessonsUser: string
    allSessionsSessionsLessonsUser: string,
    enrollSession: string
  };
  storage?: {
    local: {
      namespace: string,
    }
  };
  scorm?: {
    viewerUrl: string,
    quizViewerUrl: string;
  };
  lrs?: {
    url: string,
    auth: string
  };
}


const supportedLanguageCodes: LanguageCode[] =
  [
    {
      code: 'it-IT',
      name: 'Italiano'
    },
    {
      code: 'en-GB',
      name: 'English'
    }
  ];


@Injectable()
export class AppEnvService {
  userLanguage: string = undefined;
  private readonly appEnvConfig: AppEnvConfig;

  constructor(private deviceService: DeviceDetectorService,
              private router: Router,
              private http: HttpClient,
  ) {
    // this.environment = Object.assign(new AppEnvironment(), ...environment)
    this.appEnvConfig = {...environment};
    this.userLanguage = window.navigator.languages
      ? window.navigator.languages[0]
      : (window.navigator.language || 'en-EN');

  }

  config = () => ({...this.appEnvConfig});
  languages = () => supportedLanguageCodes;

  detectInfo(username): Observable<any> {
    const details = new EnvironmentDetails();
    details.deviceInfo = this.deviceService.getDeviceInfo();
    let platform = '';
    if (this.deviceService.isMobile()) {
      platform = 'Mobile';
    } else if (this.deviceService.isTablet()) {
      platform = 'Tablet';
    } else if (this.deviceService.isDesktop()) {
      platform = 'Desktop';
    }
    details.platform = platform;
    details.url = window.location.origin;
    details.page = this.router.url;
    details.localStorage = localStorage;
    details.username = username;

    const url = this.appEnvConfig.env.dignostic;
    return this.http.post(url, details);
  }


}
