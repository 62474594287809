import {Injectable} from '@angular/core';
import {CoursesAction, LmsCoursesActions} from './actions';
import {combineEpics, ofType} from 'redux-observable';
import {catchError, startWith, switchMap} from 'rxjs/operators';
import {LmsCoursesService} from './service';
import {LocalStorageService} from "@shared/app/storage/local/service";
import {of} from "rxjs";
import {CoursesState} from "@shared/app/lms/courses/store/reducers";
import {filter, mergeMap} from "rxjs/operators";

@Injectable()
export class LmsCoursesEpics {
  constructor(
    private actions: LmsCoursesActions,
    private service: LmsCoursesService,
    private localStorageService: LocalStorageService) {

  }

  create = () => combineEpics(
    this.createLoadCoursesEpic(),
    this.createOpenCourseEpic(),
    this.createLockCourseEpic(),
    this.createUnlockCourseEpic()
  );


  private lockCourse = () => {
    const uuid = this.localStorageService.getSessionUuid();
    return this.service.acquireLock(uuid).pipe(switchMap(() => of(this.actions.courseLocked(uuid))))
  }

  private unlockCourse = (state: CoursesState) => this.service.checkLock().pipe(
    filter((lockedResourceId) => (!!lockedResourceId && (state?.lockedResourceId === lockedResourceId))),
    mergeMap(() => this.service.releaseLock()),
    switchMap(() => of(this.actions.courseUnlocked()))
  )

  private openCourse = (courseId: number) =>
    this.service.getCourse(courseId).pipe(
      switchMap(course => of(this.actions.courseOpened(course))),
      catchError((error) => of(this.actions.courseOpenError(error))),
      startWith(this.actions.courseOpening())
    )

  private openCourseByPathId = (courseId: number, pathId: number) =>
    this.service.getCourseByPathId(courseId, pathId).pipe(
      switchMap(course => of(this.actions.courseOpened(course))),
      catchError((error) => of(this.actions.courseOpenError(error))),
      startWith(this.actions.courseOpening())
    )

  private loadCourses = (state: CoursesState) => {
    // state?.lockedResourceId && this.service.releaseLock().subscribe()
    return this.service.loadCourses().pipe(
      switchMap(courses => of(this.actions.coursesLoaded(courses))),
      catchError((error) => of(this.actions.coursesLoadFailed(error))),
      startWith(this.actions.coursesLoading())
    )
  };

  private createOpenCourseEpic = () =>
    (action$, state$) =>
      action$.pipe(
        ofType(LmsCoursesActions.COURSE_OPEN),
        switchMap((action: CoursesAction) => this.openCourse(+action.meta)),
      );

  private createLockCourseEpic = () =>
    (action$, state$) =>
      action$.pipe(
        ofType(LmsCoursesActions.COURSE_LOCK),
        switchMap((action: CoursesAction) => this.lockCourse()),
      );

  private createLoadCoursesEpic = () =>
    (action$, state$) =>
      action$.pipe(
        ofType(LmsCoursesActions.COURSES_LOAD),
        switchMap(() => this.loadCourses(state$.value.lms?.courses))
      );

  private createUnlockCourseEpic = () =>
    (action$, state$) =>
      action$.pipe(
        ofType(LmsCoursesActions.COURSE_UNLOCK),
        switchMap(() => this.unlockCourse(state$.value.lms?.courses))
      );

}
