import {Injectable} from "@angular/core";
import {AppEnvService} from "@shared/app/config/env/service";
import {HttpClient} from "@angular/common/http";
import {ResourceService} from "@shared/app/lms/resources/resource.service";
import {Observable} from "rxjs";
import {Resource} from "@shared/app/lms/resources/model";

@Injectable()
export class LmsResourcesService {

  constructor(
    private resourceService: ResourceService,
    private envService: AppEnvService,
    private http: HttpClient
  ) {
  }

  getResource = (resourceId: number): Observable<Resource> => this.resourceService.getResource(resourceId);
  downloadCertificate = (courseId: number) => this.resourceService.downloadCertificate(courseId);
  trackResource = (resourceId: number, courseId: number, pathId: number, catalogId: number): Observable<Object> => this.resourceService.setResourceOpened(resourceId, courseId, pathId, catalogId)


}

