import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {DomSanitizer, SafeStyle} from '@angular/platform-browser';
import {Observable, Subscription} from 'rxjs';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {Router} from '@angular/router';
import {select} from '@angular-redux/store';
import {User, UserDetails} from '../../user/model';
import {ICustomThemeConfig, Theme} from '../../theme/model';
import {UserService} from '../../user/service';
import {SepModalService} from '../../common/_services/modal.service';
import {LocalStorageService} from '../../storage/local/service';
import {AuthActions} from '../../authentication/store/actions';
import {UserAuthorizations} from '../../user/authorization/authorizations';
import {AppEnvService} from '../../config/env/service';
import {ImpersonateComponent} from '../../authentication/impersonate/impersonate.component';
import {flatMap, map} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './component.html',
  styleUrls: ['./component.scss']
})
export class HeaderComponent {

  error = false;
  theme: Theme;
  bsModalRef: BsModalRef;
  userIsImpersonating = false;
  warningModalRef: BsModalRef;

  @Input()
  helpOnLine: boolean;
  @Output()
  onLineHelpClick = new EventEmitter<any>();

  @ViewChild('sendDiagnosticWarningTemplate')
  warningTmpl: TemplateRef<any>;

  @select(['user', 'details'])
  readonly user!: Observable<UserDetails>;
  @select(['theme', 'config'])
  readonly themeConfig!: Observable<ICustomThemeConfig>;

  navMenuRequiredAuthorizations: string[];

  constructor(
    private modalService: BsModalService,
    private userService: UserService,
    private envService: AppEnvService,
    private sanitizer: DomSanitizer,
    private sepModalService: SepModalService,
    private router: Router,
    private lsService: LocalStorageService,
    private authenticationActions: AuthActions,
    private translateService: TranslateService
  ) {
    this.navMenuRequiredAuthorizations = [UserAuthorizations.SEP_USER_COURSES_READ, UserAuthorizations.SEP_USER_PATHS_READ];
  }

  ngOnInit(): void {

  }

  sanitize = (url) => this.sanitizer.bypassSecurityTrustStyle(`url(${url})`);

  ngOnDestroy(): void {

  }

  onLoginAsClick(sender) {
    this.bsModalRef = this.modalService.show(ImpersonateComponent);

  }

  onLogoutAsClick = (sender) => {
    if (this.lsService.get('impersonate')) {
      this.authenticationActions.impersonateLogout();
    } else {
      this.authenticationActions.logoutUser();
    }
  }

  impersonate() {
    this.user.pipe(
      map(u => `${this.envService.config().env.adminImpersonateExternalUrl}/${u.id}`),
    ).subscribe(url => window.open(url));
  }

  detectInfo() {
    this.warningModalRef = this.modalService.show(this.warningTmpl);
  }

  confirmWarningOpenedResource() {
    this.warningModalRef.hide();
    this.user.pipe(
      flatMap(user => this.envService.detectInfo(user.username))
    ).subscribe(
      data => {
        // console.log(data);
      },
      error => {
      }
    );
  }

  cancelWarningOpenedResource() {
    this.warningModalRef.hide();
  }

  showLogoutMenuItem() {
    return true;
    //showLogoutLink && !user.sso_login
  }

  openHelpOnLine = (e: Event) => this.onLineHelpClick.emit(e);
}
