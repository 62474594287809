import { Theme } from './model';

export const das: Theme = {
  name: 'das',
  properties: {
    '--background': '#1F2125',
    '--signin-header-logo-image-url': 'url(/assets/images/logo.png)',
    '--on-background': '#fff'
  }
};
