import {ScormUnitAction, ScormUnitActions, ScormUnitPayload} from "./actions";
import {ScormError} from "../../model";
import {ScormUnit} from "../model";

export interface ScormUnitState {
  current: ScormUnit,
  opening: boolean,
  opened: boolean,
  error: ScormError,
  hasNext: boolean,
  hasPrev: boolean
}

const initialState: ScormUnitState = ({
  current: undefined,
  opening: false,
  opened: false,
  error: undefined,
  hasNext: false,
  hasPrev: false

});

export const scormUnitReducer = () => (state: ScormUnitState = initialState, action: ScormUnitAction<ScormUnitPayload>): ScormUnitState => {
  switch (action.type) {
    case ScormUnitActions.SCORM_UNIT_OPENING:
      return {
        ...state,
        opened: false,
        opening: true,
        current: {...(action as ScormUnitAction<ScormUnit>).payload}
      };
    case ScormUnitActions.SCORM_UNIT_OPENED:
      return {
        ...state,
        opening: false,
        opened: true,
        current: {...(action as ScormUnitAction<ScormUnit>).payload}
      };
    case ScormUnitActions.SCORM_UNIT_ERROR:
      return {
        ...state,
        opened: false,
        opening: false,
        current: undefined,
        error: {...(action as ScormUnitAction<ScormError>).payload}
      };
    case ScormUnitActions.SCORM_UNIT_CLOSED:
      return {
        ...state,
        opening: false,
        opened: false,
        current: undefined
      }
    case ScormUnitActions.SCORM_UNIT_HAS_NEXT:
      return {
        ...state,
        hasNext: (action as ScormUnitAction<boolean>).payload
      }
    case ScormUnitActions.SCORM_UNIT_HAS_PREV:
      return {
        ...state,
        hasPrev: (action as ScormUnitAction<boolean>).payload
      }
  }
  return state
};

