import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'courseName'})
export class CourseNamePipe implements PipeTransform {
  transform(courses: any, searchText: any): any {
    if (searchText == null) {
      return courses;
    }

    return courses.filter(function (course) {
      if (course.title !== undefined) {
        return course.title.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
      } else if (course.course_title !== undefined) {
        return course.course_title.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
      }
    });
  }

  transformByStatus(courses: any, searchText: any): any {
    if (searchText == null) {
      return courses;
    }

    return courses.filter(function (course) {
      if (course.state !== undefined) {
        return course.state.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
      }

    });
  }
}
