import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error-default.component.html',
  styleUrls: ['./error-default.component.scss']
})
export class ErrorDefaultComponent implements OnInit {

  errorCode: string;
  errorDetail: string;

  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
        this.errorCode = params['errorCode'];
        this.errorDetail = params['errorMessage'] ? decodeURI(params['errorMessage']) : 'There is a generic error in the application, contact your administrator.';
      });
  }

}
