import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, Pipe, PipeTransform} from '@angular/core';
import {Resource} from '../../resources/model';
import {forkJoin, Observable, Subscription} from 'rxjs';
import {CourseSync, Lesson, Session} from './model';
import {SynchronousService} from '../synchronous.service';
import {select} from '@angular-redux/store';
import {UserDetails} from '@shared/app/user/model';
import {TranslateService} from '@ngx-translate/core';
import {NotificationsService} from 'angular2-notifications';
import {
  LessonLocationType,
  RolesRegistration,
  UserSessionStatus,
  UserSessionStatusLang,
  VisibleSessionsLessons,
  SessionState
} from '@shared/app/lms/synchronous/model';
import {CourseService} from '@shared/app/lms/courses/course.service';
import {LmsPathsActions} from '@shared/app/lms/paths/store/actions';


@Component({
  selector: 'app-course-synchronous',
  templateUrl: './course-synchronous.component.html',
  styleUrls: ['./course-synchronous.component.scss']
})
export class CourseSynchronousComponent implements OnInit, OnDestroy {


  courseSync: CourseSync;
  subscriptions: Subscription[] = [];
  EmailUser: string;
  Username: string;
  filterSessionsLessons: string;
  UserSessionStatusLang = UserSessionStatusLang;
  UserSessionStatus = UserSessionStatus;
  RolesRegistration = RolesRegistration;
  SessionState = SessionState;
  VisibleSessionsLessons = VisibleSessionsLessons;
  LessonLocationType = LessonLocationType;
  @select(['user', 'details']) userDetails$: Observable<UserDetails>;
  @Output() lessonSelected = new EventEmitter<Lesson>();
  @Output() unitClosed = new EventEmitter<any>();
  @Output() resourceLoaded = new EventEmitter<CourseSync>();

  constructor(private syncService: SynchronousService,
              private translateService: TranslateService,
              private notificationService: NotificationsService,
              private courseService: CourseService,
              private lmsPathsActions: LmsPathsActions
  ) {

    this.filterSessionsLessons = VisibleSessionsLessons.available;
  }

  _resource: Resource;

  @Input()
  set resource(r: Resource) {
    this._resource = r;
    this.courseSync = new CourseSync();
    Object.assign(this.courseSync, this._resource.metadata);
    this.courseSync.resourceId = this._resource.id;
    this.courseSync.courseId = this._resource.courseId;
    this.courseSync.pathId = this._resource.pathId;
    this.courseSync.catalogId = this._resource.catalogId;
    this.courseSync.duration = this._resource.duration;
    this.courseSync.locked = this._resource.locked;
    this.courseSync.sessions.map(s => {
      s.lessons.map(l => {
        l.isPlaying = this.syncService.canOpenVdc(l);
        return l;
      });
      return s;
    });
    this.resourceLoaded.emit(this.courseSync);
  }

  ngOnInit() {
    this.userDetails$.subscribe(x => {
      this.EmailUser = x.email;
      this.Username = x.username;
    });
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  enrollToSession(session: Session) {
    this.courseService.enrollSession(session).subscribe(
      data => {
        this.lmsPathsActions.openPath(this._resource.pathId);
        this.resourceLoaded.emit(this.courseSync);
      },
      err => {
        forkJoin(
          this.translateService.get('Warning'),
          this.translateService.get(err.error.message)
        ).subscribe(t => {
          this.notificationService.error(t[0], t[1], {timeOut: 5000});
        });
      }
    );
  }

  removeFromSession(session: Session) {
    this.lmsPathsActions.openPath(this._resource.pathId);
    this.courseService.removeFromSession(session).subscribe(
      data => {
        this.lmsPathsActions.openPath(this._resource.pathId);
        this.resourceLoaded.emit(this.courseSync);
      },
      err => {
        forkJoin(
          this.translateService.get('Warning'),
          this.translateService.get(err.error.message)
        ).subscribe(t => {
          this.notificationService.error(t[0], t[1], {timeOut: 5000});
        });
      }
    );
  }

  unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  openLesson(lesson: Lesson) {
    if (lesson.isPlaying) {
      const vdcUrlSubscription = this.syncService.getVdcLink(lesson.id, lesson.location, this.EmailUser, this.Username, UserSessionStatus.USER).subscribe(c => {
        lesson.vdcUrl = c.vdcUrl;
        lesson.vdcType = c.vdcType;
        this.openVdcCourse(lesson);
        vdcUrlSubscription.unsubscribe();
      }, error => {
        forkJoin(
          this.translateService.get('Warning'),
          this.translateService.get(error.error.message)
        ).subscribe(t => {
          this.notificationService.error(t[0], t[1], {timeOut: 5000});
        });
      });
    } else {
      this.translateService.get('Can\'t open a future or past lesson').subscribe(
        t => this.notificationService.warn(t, null, {timeOut: 5000})
      );
    }
  }

  openVdcCourse(lesson: Lesson) {
    this.lessonSelected.emit(lesson);
  }



  openNonVdcLesson(lesson: Lesson) {
    lesson.location = lesson.location;
    if (lesson.isPlaying) {
      if (lesson.location.includes('adobeconnect.com')) {
        lesson.location += ('?guestName=' + this.Username);
      }
      window.open(lesson.location);
    } else {
      this.translateService.get('Can\'t open a future or past lesson').subscribe(
        t => this.notificationService.warn(t, null, {timeOut: 5000})
      );
    }
  }

}

@Pipe({name: 'filterLesson'})
export class FilterLessonPipe implements PipeTransform {
  transform(value: Lesson[], args: { showOnlyTodayLessons: string }): Lesson[] {
    if (args.showOnlyTodayLessons == null || args.showOnlyTodayLessons === VisibleSessionsLessons.all || args.showOnlyTodayLessons === VisibleSessionsLessons.available) {
      return value;
    }

    return value.filter(l => {
      return new Date(l.dateStart).toDateString() === new Date().toDateString();
    });
  }
}


@Pipe({name: 'filterSession'})
export class FilterSessionPipe implements PipeTransform {
  transform(value: Session[], args: { showOnlyTodaySession: string }): Session[] {
    if (args.showOnlyTodaySession == null || args.showOnlyTodaySession === VisibleSessionsLessons.all) {
      return value;
    } else if (args.showOnlyTodaySession === VisibleSessionsLessons.available) {
      return value.filter(s => {
        return s.role === UserSessionStatus.USER || s.role === UserSessionStatus.WAITING || s.role === UserSessionStatus.ASSIGNABLE  ? true : false;
      });
    }

    return value.filter(s => {
      return s.lessons.filter(l => {
        return new Date(l.dateStart).toDateString() === new Date().toDateString();
      }).length > 0;
    });
  }
}
