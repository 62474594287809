import { ReportService } from '../_services/report.service';
import { Component, OnInit, AfterContentInit, AfterViewInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  BreadCrumbFactory,
  BreadcrumbLevel,
  BreadcrumbService,
  SepBreadcrumb
} from "../../common/_services/breadcrumb.service";
import {Stats} from "../../user/stats/model";
import {forkJoin} from "rxjs";

@Component({
  selector: 'app-report-ivass',
  templateUrl: './report-ivass.component.html',
  styleUrls: ['./report-ivass.component.scss']
})
export class ReportIvassComponent implements OnInit {

  constructor(
      private reportService: ReportService,
      private translateService: TranslateService,
      private bcService: BreadcrumbService) { }

  bannerTitle: string;
  bannerDescription: string;

  data: Stats;
  public barChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true,
    maintainAspectRatio: true
  };
  barChartLabels = [];
  barChartType = 'line';
  barChartLegend = true;
  barChartData = [];
  colors = [
    { // dark grey
      backgroundColor: 'rgba(77,83,96,0.2)',
      borderColor: 'rgba(77,83,96,1)',
      pointBackgroundColor: 'rgba(77,83,96,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: 'rgba(77,83,96,1)',
      pointHoverBorderColor: 'rgba(77,83,96,1)'
    },
    { // grey
      backgroundColor: 'rgba(148,159,177,0.2)',
      borderColor: 'rgba(148,159,177,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: 'rgba(148,159,177,1)',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    }
  ];
  graph1DataReady = false;
  graphHasValues = false;

  ngOnInit() {
      this.translateService.get(['Report IVASS']).subscribe(t => {
          this.bannerTitle = t['Report IVASS'];
      });
      //disable stat for IVASS must check if it's right
//       this.reportService.getStats().subscribe(s => {
//           this.data = s;
//           const _g1Data = [];
//           const _mandatoryHValues = [];
//           const _optionalHValues = [];
//           for (let d of this.data.ivass.courseYears) {
//               this.barChartLabels.push(d.courseYear.toString());
//               _mandatoryHValues.push(d.yearDurationMandatory);
//               _optionalHValues.push(d.yearDurationOptional);
//               if (d.yearDurationMandatory > 0 || d.yearDurationOptional > 0) {
//                   this.graphHasValues = true;
//               }
//           }
//           forkJoin(
//               this.translateService.get('Duration mandatory'),
//               this.translateService.get('Duration optional'),
//               this.translateService.get('Report IVASS')
//           ).subscribe(t => {
//               this.barChartData.push({ label: t[0], data: _mandatoryHValues});
//               this.barChartData.push({ label: t[1], data: _optionalHValues});
//
// //              this.barChartData = [
// //                {"label": t[0], "data": [ 10, 20, 15, 34 ] },
// //                {"label": t[1], "data": [ 4, 9, 1, 45 ] }
// //              ];
// //              this.graphHasValues = true;
//               this.graph1DataReady = true;
//
//               const b: SepBreadcrumb = (new BreadCrumbFactory).createBreadCrumb(t[2], null, 'report/ivass', BreadcrumbLevel.first);
//               this.bcService.setBreadcrumb(b);
//           });
//       });
  }

}
