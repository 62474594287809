import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, Params, PRIMARY_OUTLET } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import {BreadcrumbService, SepBreadcrumb} from "../../common/_services/breadcrumb.service";


@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  breadcrumbs: Observable<SepBreadcrumb[]>;

  constructor( private bcService: BreadcrumbService) {
  }

  ngOnInit() {
    //this.bcService.getBreadcrumbs().subscribe( b => { this.breadcrumbs = b; });
    this.breadcrumbs = this.bcService.getBreadcrumbs()
    const ROUTE_DATA_BREADCRUMB = 'breadcrumb';

  }

}
