import {NgModule} from '@angular/core';
import {AppComponent} from './component';
import {AppBootModule} from './boot/module';
import {AuthenticationModule} from './authentication/module';
import {HttpModule} from './http/module';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {AppCommonModule} from './common/module';
import {StoreModule} from './store/module';
import {NgReduxModule} from '@angular-redux/store';
import {NgReduxRouterModule} from '@angular-redux/router';
import {ThemeModule} from './theme/module';
import {ModalModule} from 'ngx-bootstrap/modal';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CoreModule} from './core/module';
import {AppConfigModule} from './config/module';
import {BrowserModule} from '@angular/platform-browser';
import {SimpleNotificationsModule} from 'angular2-notifications';
import {LayoutModule} from './layout/module';
import {RouterModule} from '@angular/router';
import {LmsModule} from './lms/module';
import {ReportModule} from './report/report.module';
import {MissingTranslationHandler, TranslateLoader, TranslateModule, TranslateParser} from '@ngx-translate/core';
import {AppTranslateLoader} from './i18n/translations/loaders';
import {AppTranslateParser} from './i18n/translations/parsers';
import {AppMissingTranslationHandler} from './i18n/translations/handlers';
import {SepModalService} from './common/_services/modal.service';
import {GenericModalComponent} from './common/generic-modal/generic-modal.component';
import {FullCalendarModule} from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {ButtonsModule} from 'ngx-bootstrap/buttons';



FullCalendarModule.registerPlugins([
  dayGridPlugin,
  timeGridPlugin,
  listPlugin,
  interactionPlugin
]);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: AppTranslateLoader,
        deps: [HttpClient]
      },
      parser: {
        provide: TranslateParser,
        useFactory: AppTranslateParser
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useFactory: AppMissingTranslationHandler
      }
    }),
    AppBootModule,
    AppCommonModule,
    AppConfigModule,
    ThemeModule,
    LayoutModule,
    LmsModule,
    ReportModule,
    AuthenticationModule,
    HttpModule,
    HttpClientModule,
    StoreModule,
    BrowserModule,
    RouterModule,
    NgReduxModule,
    NgReduxRouterModule.forRoot(),
    FormsModule,
    ModalModule.forRoot(),
    CoreModule.forRoot(),
    SimpleNotificationsModule.forRoot(),
    FullCalendarModule,
    PopoverModule.forRoot(),
    ReactiveFormsModule,
    ButtonsModule.forRoot()
  ],
  providers: [SepModalService],
  exports: [
    AppComponent,
    AppCommonModule,
    AppBootModule,
    AppConfigModule,
    ThemeModule,
    LayoutModule,
    LmsModule,
    ReportModule,
    AuthenticationModule,
    StoreModule,
    BrowserModule,
    RouterModule,
    CoreModule,
    ModalModule,
    FormsModule,
    HttpModule,
    HttpClientModule,
    NgReduxModule,
    NgReduxRouterModule,
    SimpleNotificationsModule,
    PopoverModule,
    ReactiveFormsModule,
    ButtonsModule
  ],
  entryComponents: [GenericModalComponent]
})
export class AppModule {

}



