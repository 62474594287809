import {Component, OnInit} from '@angular/core';
import {select} from '@angular-redux/store';
import {Observable} from 'rxjs';
import {AuthState} from '../../../../../../src/app/authentication/store/reducers';
import {Router} from '@angular/router';
import {UserState} from '../../../../../../src/app/user/model';
import {HeaderComponent} from '@shared/app/layout/header/component';
import {BsModalService} from 'ngx-bootstrap/modal';
import {UserService} from '@shared/app/user/service';
import {AppEnvService} from '@shared/app/config/env/service';
import {DomSanitizer} from '@angular/platform-browser';
import {SepModalService} from '@shared/app/common/_services/modal.service';
import {LocalStorageService} from '@shared/app/storage/local/service';
import {AuthActions} from '@shared/app/authentication/store/actions';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-das-header',
  templateUrl: './das-header.component.html',
  styleUrls: ['./das-header.component.scss', '../../../../../../src/app/layout/header/component.scss']
})
export class DasHeaderComponent extends HeaderComponent implements OnInit {

  @select('auth')
  readonly authState: Observable<AuthState>;

  @select('user')
  readonly userState: Observable<UserState>;

  constructor(private modalServiceR: BsModalService,
              private userServiceR: UserService,
              private envServiceR: AppEnvService,
              private sanitizerR: DomSanitizer,
              private sepModalServiceR: SepModalService,
              private routerR: Router,
              private lsServiceR: LocalStorageService,
              private authenticationActionsR: AuthActions,
              private translateServiceR: TranslateService) {
    super(modalServiceR,
      userServiceR,
      envServiceR,
      sanitizerR,
      sepModalServiceR,
      routerR,
      lsServiceR,
      authenticationActionsR,
      translateServiceR);
  }

  goToHome = () => this.routerR.navigate(['/home']);


}
