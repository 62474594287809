import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthActions} from '@shared/app/authentication/store/actions';
import {flatMap, map} from 'rxjs/operators';
import {filter} from 'rxjs/operators';
import {from, Observable, Subscription} from 'rxjs';
import {select} from '@angular-redux/store';
import {AuthState} from '@shared/app/authentication/store/reducers';

@Component({
  template: ''
})

export class RefreshTokenLoginComponent implements OnInit, OnDestroy {

  @select('auth')
  readonly authState: Observable<AuthState>;

  subscriptions: Subscription[] = [];
  returnUrl: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authActions: AuthActions) {
  }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/home';
    this.subscriptions.push(
      this.authState.pipe(
        filter(state => !!state && state.login_succeeded),
        flatMap(() => from(this.router.navigate([this.returnUrl]))),
      ).subscribe()
    );

    this.subscriptions.push(
      this.route.params.subscribe(params => {
        this.authActions.refreshTokenLogin(params['tempToken']);
      }));

    this.route.queryParamMap
      .subscribe((params) => {
          this.authActions.refreshTokenLogin(params['params'].refreshToken);
        }
      );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
