import {ResourcePathAware} from '../../resources/model';

export class Lesson {
  id: number;
  sessionId: number;
  description: string;
  dateStart: string;
  dateEnd: string;
  location: string;
  showLocation: string;
  deleted: number;
  vdcUrl: string;
  vdcType: string;
  isPlaying: boolean;
}

export class Session extends ResourcePathAware {

  id: number;
  resourceId: number;
  minUsers: number;
  maxUsers: number;
  description: string;
  state: string;
  bookingEndDate: Date;
  expirationDate: Date;
  waitingList: number;
  rolesRegistration: string;
  trainingContact: string;
  deleted: number;
  role: string;
  lessons: Lesson[];
  isLoading: boolean;
}

export class CourseSync extends ResourcePathAware {
  sessions: Session[];
  duration: number;
  locked: boolean;
}

export class VdcUrlResponse {
  vdcUrl: string;
  vdcType: string;

}

export enum VDCTYPE {
  ZOOM= 'ZOOM',
  TEAMS = 'TEAMS',
  CONNECT = 'CONNECT'
}
