import {UIInfo} from '../model';
import {ScormModule} from '../scorm/module/model';
import {Session} from '@shared/app/lms/synchronous/course/model';

export class ResourcePathAware extends UIInfo {
  resourceId: number;
  courseId: number;
  pathId: number;
  catalogId: number;
}

export class Resource extends ResourcePathAware {
  id: number;
  title: string;
  description: string;
  state: string;
  statusView: string;
  image: string;
  type: string;
  order: number;
  mandatory: boolean;
  metadata: ResourceMetadata;
  url: string;
  duration: number;
  openInNewWindow: boolean;
  collapsed: boolean;
  locked: boolean;
  lockReason: string;
  imageUrl: string;
}

export class ResourceMetadata {
  id: number;
  title: string;
  description: string;
  type: string;
  url: string;
  cover: string;
  modules: ScormModule[];
  sessions?: Session[];
}

export enum ResourceType {
  SCORM = 'SCORM',
  XAPI = 'XAPI',
  SYNCHRONOUS = 'SYNCHRONOUS',
  DOCUMENT = 'DOCUMENT',
  CERTIFICATE = 'CERTIFICATE'
}
