<app-banner
            [display]="'thin'"
            [title]="bannerTitle"
            [description]="bannerDescription"
            [backgroundImage]="'assets/img/course-banner.jpg'"
            [colorSchema]="'light'"
></app-banner>

<div class="catalog-container page-template-thin">
  <div [spinner]="loading"></div>
  <div class="catalog-content" *ngIf="paths" @pageAnimations>


    <div class="row path-item" *ngFor="let path of paths" [@mouseOver]="path['mouse']"  (click)="openPath(path)" (mouseover)="mouseover(path)" (mouseout)="mouseout(path)" >
      <div class="col-sm-2 image-container clickable" [style.background-image]="path.imageUrl" (click)="openPath(path)">
      </div>
      <div class="col-sm-8 details-container">
        <div class="path-title size-1 brand-color clickable" (click)="openPath(path)">{{path.title}}</div>
        <div [innerHTML]="path.description"></div>
        <div class="details-bottom">
          <a (click)="openPath(path)" class="catalogdetails clickable"><i class="fa fa-plus"></i><span
            translate>Details</span></a>
        </div>
        <div *ngIf="path.theme" class="path-theme">{{path.theme}}</div>
      </div>
      <div class="col-sm-2 buttons-container">
        <button class="btn btn-primary clickable" *ngIf="path.enrolled !== true" (click)="enrollToPath(path); $event.stopPropagation();"><span
          translate>enroll</span>&nbsp;<i class="fa fa-user-plus"></i></button>
        <span *ngIf="path.enrolled === true" class="course-enrolled"><i class="fa fa-check"></i>&nbsp;<span translate>enrolled</span></span>
      </div>
    </div>
  </div>

  <div *ngIf="paths && paths.length == 0 && loading === false" class="alert alert-info">
    <a [routerLink]="['/courses']"><b translate>You can find your learning path into MY COURSES section</b></a>
    <i class="fa fa-frown-o" aria-hidden="true"></i>
  </div>
</div>
