<app-banner
  [display]="'thin'"
  [title]="bannerTitle"
  [description]="bannerDescription"
  [backgroundImage]="'assets/img/course-banner.jpg'"
  [colorSchema]="'light'"
></app-banner>


<div class="catalog-container page-template-wide" *ngIf="(sessions | async)">
  <div class="catalog-content">
    <div class="row path-item">
      <div class="col-12">
        <div [spinner]="loading === true"></div>
        <div>
          <app-calendar *ngIf="loading === false && userLocale" #calendar [calendarOptions]="calendarOptions"
                        [locale]="userLocale" [popoverTmpl]="popoverTmpl"></app-calendar>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="loading === false && calendarOptions?.eventSources.length === 0" class="alert alert-info"><span translate>No sessions available</span>
    <i
      class="fa fa-frown-o" aria-hidden="true"></i>
  </div>
</div>


<ng-template #popoverTmpl let-data="data">
  <div class="row">
    <div class="col-6">
      <dl class="row mb-0">
        <dt class="col-5" translate>Session</dt>
        <dd class="col-7">{{data.extendedProps.extraParams.description}}</dd>
      </dl>
    </div>
    <div class="col-6">
      <dl class="row mb-0">
        <dt class="col-5"><span translate>Lessons</span></dt>
        <dd class="col-7">{{data.extendedProps.extraParams.lessonDescription}}</dd>
      </dl>
    </div>
  </div>
  <hr class="mb-2 mt-1">
  <div class="row">
    <div class="col-6">
      <dl class="row mb-0">
        <dt class="col-5"><i class="fa fa-info-circle"></i>&nbsp;<span translate>Total lessons</span></dt>
        <dd class="col-7">{{data.extendedProps.extraParams.lessonsCount}}</dd>
      </dl>
    </div>
    <div class="col-6">
      <dl class="row mb-0">
        <dt class="col-5"><i class="fa fa-info-circle"></i>&nbsp;<span translate>Your status</span></dt>
        <dd class="col-7" translate>{{UserSessionStatusLang[data.extendedProps.extraParams.role]}}</dd>
      </dl>
    </div>
  </div>
  <hr class="mb-2 mt-1">
  <div class="row">
    <div class="col-6">
      <dl class="row mb-0">
        <dt class="col-5"><i class="fa fa-calendar"></i>&nbsp;<span translate>Delivery date</span></dt>
        <dd class="col-7">{{data.start | date : 'dd/MM/yyyy' }}</dd>
      </dl>
      <dl class="row mb-0 mt-1">
        <dt class="col-5"><i class="fa fa-clock-o"></i>&nbsp;<span translate>Time</span></dt>
        <dd class="col-7">{{data.start | date : 'HH:mm'}} - {{data.end | date : 'HH:mm'}}</dd>
      </dl>
    </div>
    <div class="col-6">
      <dl class="row mb-0">
        <dt class="col-5"><i class="fa fa-hourglass-half"></i>&nbsp;<span translate>Booking End Date</span></dt>
        <dd class="col-7">{{data.extendedProps.extraParams.bookingEndDate | date : 'dd/MM/yyyy'}}</dd>
      </dl>
      <dl class="row mb-0 mt-1">
        <dt class="col-5"><i class="fa fa-users"></i>&nbsp;<span translate>Capacity</span></dt>
        <dd class="col-7">{{data.extendedProps.extraParams.maxUsers}}</dd>
      </dl>
    </div>
  </div>
  <hr class="mb-2 mt-1">
  <div class="row">
    <div class="col-12">
      <dl class="row mb-0">
        <dt class="col-2"><i class="fa fa-address-card"></i>&nbsp;<span translate>Contact</span></dt>
        <dd class="col-10" [innerHTML]="data.extendedProps.extraParams.trainingContact | linkify"></dd>
      </dl>
    </div>
  </div>
  <hr class="mb-2 mt-1" *ngIf="data.extendedProps.extraParams.showLocation === LessonLocationType.SHOW">
  <div class="row" *ngIf="data.extendedProps.extraParams.showLocation === LessonLocationType.SHOW">
    <div class="col-12">
      <dl class="row mb-0">
        <dt class="col-2"><i class="fa fa-map-marker"></i>&nbsp;<span translate>Location</span></dt>
        <dd class="col-10" [innerHTML]="data.extendedProps.extraParams.location | linkify | translate"></dd>
      </dl>
    </div>
  </div>

  <div class="row mb-1">
    <div class="col-12 text-right">
      <button class="btn btn-sm btn-enter pull-right"
              *ngIf="data.extendedProps.extraParams.role === UserSessionStatus.USER || data.extendedProps.extraParams.role === UserSessionStatus['MAIN TEACHER']"
              [routerLink]="['/path',data.extendedProps.extraParams.pathId,'course', data.extendedProps.extraParams.courseId]">
        <span translate>go to course</span>
      </button>
      <button class="btn btn-sm btn-enter pull-right"
              *ngIf="data.extendedProps.extraParams.role === UserSessionStatus.ASSIGNABLE"
              [routerLink]="['/catalogcourse',data.extendedProps.extraParams.pathId,'session',data.extendedProps.extraParams.sessionId]">
        <span translate>go to path</span>
      </button>
      <span *ngIf="data.extendedProps.extraParams.role === UserSessionStatus['OUT OF DATE']">
        <span translate>out of date</span>
      </span>
    </div>
  </div>
</ng-template>


