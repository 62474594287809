import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ReportIvassComponent } from './report-ivass/report-ivass.component';
import { ReportUserComponent } from './report-user/report-user.component';
import {TranslateModule} from '@ngx-translate/core';
import {AuthGuard} from "../authentication/guard";

const routes: Routes = [
    { path: 'report',
      children: [
        { path: 'user', component: ReportUserComponent, canActivate: [AuthGuard], data: { template: 'wide' } },
        { path: 'ivass', component: ReportIvassComponent, canActivate: [AuthGuard], data: { template: 'wide' } }
      ]}
];

@NgModule({
  imports: [RouterModule.forChild(routes),TranslateModule.forChild({})],
  exports: [RouterModule]
})
export class ReportRoutingModule { }
