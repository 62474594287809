import {Injectable} from "@angular/core";
import {CourseService} from "../../courses/course.service";
import {AppEnvService} from "../../../config/env/service";
import {HttpClient} from "@angular/common/http";
import {LmsPath} from "./model";
import {map, mergeMap} from "rxjs/operators";
import {LmsCourse} from "../../courses/store/model";

@Injectable()
export class LmsPathsService {

  constructor(
    private courseService: CourseService,
    private envService: AppEnvService,
    private http: HttpClient
  ) {


  }

  loadPathList = (filter?: string) => {
    const url = `${this.envService.config().api.courses}/me${filter ? `?themeId:${filter}` : ''}`;
    return this.http.get<Array<LmsPath>>(url)
  }

  getPath = (id: number) => {
    const url = `${this.envService.config().api.paths}/${id}`;
    return this.http.get<LmsPath>(url);
  }

  private getCourse = (id: number) => {
    const url = `${this.envService.config().api.courses}/${id}`;
    return this.http.get<LmsCourse>(url)
  }

  getPathCourses = (id: number) => {
    const url = `${this.envService.config().api.paths}/${id}`;
    this.http.get<LmsPath>(url).pipe(
      mergeMap(path => path.courses.map(course => this.getCourse(course.id)))
    );
  }

}