export enum UserSessionStatusLang {
  USER = 'enrolled',
  WAITING = 'in waiting list',
  ASSIGNABLE = 'subscribable',
  'OUT OF DATE' = 'the booking end date is past',
  'MAIN TEACHER' = 'main teacher'
}

export enum UserSessionStatus {
  USER = 'USER',
  WAITING = 'WAITING',
  ASSIGNABLE = 'ASSIGNABLE',
  'OUT OF DATE' = 'OUT OF DATE',
  'MAIN TEACHER' = 'MAIN TEACHER'
}

export enum RolesRegistration {
  MANAGER = 'MANAGER',
  USER = 'USER',
  'MANAGER-PRIVATE' = 'MANAGER-PRIVATE'
}

export enum VisibleSessionsLessons {
  available = 'available',
  today = 'today',
  all = 'all'
}

export enum LessonLocationType {
  'VDC' = 'VDC',
  'SHOW' = 'SHOW',
  'HIDE' = 'HIDE'
}

export enum SessionState {
  'CONFIRMED' = 'CONFIRMED',
  'TO BE CONFIRMED' = 'TO BE CONFIRMED',
  'CLOSED' = 'CLOSED'
}


export class LessonsSessions {
  lessonSession: LessonSession[];
}

export class LessonSession {
  courseCatalogMetaId: number;
  courseCatalogId: number;
  courseId: number;
  pathId: number;
  enrolled: Date;
  catalogId: number;
  id_user: number;
  role: string;
  date_start: string;
  date_end: string;
  lessonDescription: string;
  id: number;
  resourceId: number;
  minUsers: number;
  maxUsers: number;
  description: string;
  state: string;
  bookingEndDate: Date;
  expirationDate: Date;
  waitingList: number;
  rolesRegistration: string;
  trainingContact: string;
  deleted: number;
  lessonId: number;
  showLocation: string;
  location: string;
}

export class SessionsCalendar {
  [key: number]: SessionCalendar
}

export class SessionCalendar {
  id: number;
  session_title: string;
  color: string;
  link: string;
  lessons: LessonCalendar[];
  lessonsCount: number;
  role: string;
  location: string;
  showLocation: string;
}

export class LessonCalendar extends LessonSession {
}

export class VdcRequest {
  lessonId: number;
  vdcId: string;
  userEmail: string;
  role: string;
  username: string;
}
