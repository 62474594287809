import {
  ApplicationRef,
  Component,
  ComponentFactoryResolver,
  Injector,
  OnDestroy,
  OnInit
} from '@angular/core';
import {BreadCrumbFactory, BreadcrumbLevel, BreadcrumbService, SepBreadcrumb} from '@shared/app/common/_services/breadcrumb.service';
import {Router} from '@angular/router';
import {CourseService} from '@shared/app/lms/courses/course.service';
import {TranslateService} from '@ngx-translate/core';
import {forkJoin, Observable, Subscription} from 'rxjs';
import {UIMessage} from '@shared/app/model';
import {
  LessonLocationType,
  LessonSession,
  SessionCalendar,
  SessionsCalendar,
  UserSessionStatus,
  UserSessionStatusLang
} from '@shared/app/lms/synchronous/model';
import {select} from '@angular-redux/store';
import {LmsLessonsActions} from '@shared/app/lms/synchronous/store/actions';
import {CalendarOptions, EventSourceInput} from '@fullcalendar/angular';
import itLocale from '@fullcalendar/core/locales/it';
import enLocale from '@fullcalendar/core/locales/en-gb';
import {UserDetails} from '@shared/app/user/model';
import {LocaleInput} from '@fullcalendar/common';

@Component({
  selector: 'app-sessions-calendar',
  templateUrl: './sessions-calendar.component.html',
  styleUrls: ['./sessions-calendar.component.scss']
})
export class SessionsCalendarComponent implements OnInit, OnDestroy {

  bannerTitle: string;
  bannerDescription: string;
  loading: boolean;
  message: UIMessage;
  subscriptions: Subscription[] = [];
  UserSessionStatusLang = UserSessionStatusLang;
  UserSessionStatus = UserSessionStatus;
  LessonLocationType = LessonLocationType;

  sessionsCalendarEvents: EventSourceInput[];
  calendarOptions: CalendarOptions;

  @select(['user', 'details']) readonly user: Observable<UserDetails>;

  @select(['lms', 'lessons', 'list']) sessions: Observable<LessonSession[]>;

  userLocale: LocaleInput;


  constructor(
    private resolver: ComponentFactoryResolver,
    private injector: Injector,
    private appRef: ApplicationRef,
    private bcService: BreadcrumbService,
    private router: Router,
    private courseService: CourseService,
    private translateService: TranslateService,
    private lessonsAction: LmsLessonsActions) {
  }

  ngOnInit(): void {
    this.calendarOptions = {
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay,listYear'
      }
    };
    this.loading = true;
    forkJoin(
      this.translateService.get('Calendar'),
      // tslint:disable-next-line:max-line-length
      this.translateService.get('Sessions calendar')
    ).subscribe((t: any[]) => {
      this.bannerTitle = t[0];
      this.bannerDescription = t[1];
      this.translateService.get('Calendar').subscribe(t => {
        const b: SepBreadcrumb = (new BreadCrumbFactory).createBreadCrumb(t, null, '/calendar', BreadcrumbLevel.first);
        this.bcService.setBreadcrumb(b);
      });
    });
    this.subscriptions.push(
      this.user.subscribe(data => {
        if (data) {
          switch (data.preferences.cultureCode) {
            case 'it-IT':
              this.userLocale = itLocale;
              break;
            default:
              this.userLocale = enLocale;
          }
          this.subscriptions.push(
            this.sessions.subscribe((dataSess) => {
              if (dataSess) {

                this.calendarOptions.eventSources = [];
                this.sessionsCalendarEvents = [];
                const session: SessionsCalendar = dataSess.reduce(function (result, obj) {
                  const key = obj.id;
                  if (!result[obj.id]) {
                    result[obj.id] = {
                      id: obj.id,
                      session_title: obj.description,
                      color: '',
                      link: '',
                      lessons: [],
                      lessonsCount: 1,
                      role: obj.role,
                      location: obj.location,
                      showLocation: obj.showLocation
                    };
                  } else {
                    result[obj.id].lessonsCount++;
                  }
                  result[obj.id].lessons.push(obj);
                  return result;
                }, {} as SessionsCalendar);

                for (const sessionItem of Object.keys(session)) {
                  const sessionObj = session[sessionItem] as SessionCalendar;
                  this.calendarOptions.eventSources.push({
                    events: sessionObj.lessons.map(lesson => {
                      return {
                        id: lesson.lessonId.toString(),
                        title: `${sessionObj.session_title} | ${lesson.lessonDescription}`,
                        start: lesson.date_start,
                        end: lesson.date_end,
                        extraParams: {
                          minUsers: lesson.minUsers,
                          maxUsers: lesson.maxUsers,
                          state: lesson.state,
                          rolesRegistration: lesson.rolesRegistration,
                          trainingContact: lesson.trainingContact,
                          enrolled: lesson.enrolled,
                          description: lesson.description,
                          lessonDescription: lesson.lessonDescription,
                          bookingEndDate: lesson.bookingEndDate,
                          waitingList: lesson.waitingList,
                          pathId: lesson.pathId,
                          courseId: lesson.courseId,
                          lessonsCount: sessionObj.lessonsCount,
                          location: lesson.location,
                          showLocation: lesson.showLocation,
                          role: sessionObj.role,
                          sessionId: sessionObj.id
                        },
                      };
                    })
                  });
                }
                this.loading = false;
              }
            }));
        }
      })
    );

    this.lessonsAction.loadPathList();
  }


  ngOnDestroy() {
    for (const s of this.subscriptions) {
      s.unsubscribe();
    }

  }
}
