import {Injectable} from '@angular/core';
import {AppEnvService} from '../../config/env/service';
import {v4 as uuid} from 'uuid';

@Injectable()
export class LocalStorageService {

  private readonly storage: Storage;
  private readonly namespace: string;
  private key = (name: string) => `${this.namespace}.${name}`;

  constructor(appEnv: AppEnvService) {
    this.storage = localStorage;
    this.namespace = appEnv.config().storage.local.namespace;
  }

  get = (key: string): string => this.storage.getItem(this.key(key));
  set = (key: string, value: string): void => this.storage.setItem(this.key(key), value);
  del = (key: string): void => this.storage.removeItem(this.key(key));

  rawGet = (key: string): string => this.storage.getItem(key);

  static readonly OAuthTokenKeyName = 'currentToken';
  static readonly UserAuthoritiesKeyName = 'userAuths';
  static readonly UserImpersonateKeyName = 'impersonate';

  getSessionUuid() {
    return uuid();
  }
}
