import {Component, ComponentFactory, ComponentFactoryResolver, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {Observable, Subscription} from "rxjs";
import {ThemeState} from "../../theme/store/reducers";
import {select} from "@angular-redux/store";
import {AppEnvService} from "../../config/env/service";
import {CatalogByThemeAreaComponent} from "./by-theme-area/catalog-by-theme-area.component";
import {CatalogDefaultComponent} from "./catalog-default/catalog-default.component";
import {map} from "rxjs/operators";
import {ComponentHostDirective} from "../../common/directives";

@Component({
  selector: 'lms-catalog',
  templateUrl: 'component.html'
})
export class LmsCatalogComponent implements OnInit, OnDestroy {

  @ViewChild(ComponentHostDirective, {static: true}) componentHost: ComponentHostDirective;

  subscriptions: Subscription[] = [];
  byAreaThemes: string[];

  @select('theme')
  readonly themeState: Observable<ThemeState>;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private environmentService: AppEnvService
  ) {
    this.byAreaThemes = this.environmentService.config().theme.views.catalog.byThemeArea;
  }

  ngOnInit(): void {

    const componentFactory = (themeState: ThemeState) => {
      return this.byAreaThemes.includes(themeState.config.name) || themeState.config.catalogByThemeArea === true
        ? this.componentFactoryResolver.resolveComponentFactory(CatalogByThemeAreaComponent)
        : this.componentFactoryResolver.resolveComponentFactory(CatalogDefaultComponent);
    };

    const createComponent = (factory: ComponentFactory<any>) => {
      this.componentHost.viewContainerRef.clear();
      this.componentHost.viewContainerRef.createComponent(factory);
    };

    this.subscriptions.push(
      this.themeState.pipe(
        map(componentFactory),
        map(createComponent)
      ).subscribe());

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}

