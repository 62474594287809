import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-elements-count',
  templateUrl: './elements-count.component.html',
  styleUrls: ['./elements-count.component.scss']
})
export class ElementsCountComponent implements OnInit {

    count: any;
    total: any;
    text: string;

    @Input()
    set elementsCount (c: any) {
        if (c) {
            if (typeof c === 'number') {
                this.count = c;
            }
            if (c.constructor === Array) {
                this.count = c.length;
            }
        }
    }

    @Input()
    set totalCount (t: any) {
        if (t !== undefined) {
            if (typeof t === 'number') {
                this.total = t;
            }
            if (t.constructor === Array) {
                this.total = t.length;
            }
        }
    }

    constructor(private translateService: TranslateService) { }

    ngOnInit() {}

}
