import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserActions} from './user/store/actions';
import {AuthActions} from './authentication/store/actions';
import {from, Observable, Subscription} from 'rxjs';
import {select} from '@angular-redux/store';
import {filter, flatMap, map} from 'rxjs/operators';
import {BootActions} from './boot/store/actions';
import {BootState} from './boot/store/reducers';
import {Router} from '@angular/router';
import {LocalStorageService} from './storage/local/service';
import {AppEnvService} from './config/env/service';
import {TranslateService} from '@ngx-translate/core';
import {I18NService} from './i18n/service';
import {ThemeState} from './theme/store/reducers';
import {SepModalService} from './common/_services/modal.service';
import {Location} from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: 'component.html',
  styleUrls: ['./component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  title = 'SeP';
  public helpOnLine: boolean;

  @select(['boot'])
  readonly bootState!: Observable<BootState>;

  @select(['theme'])
  readonly themeState: Observable<ThemeState>;

  @select(['auth', 'authenticated'])
  readonly authenticated!: Observable<boolean>;

  @select(['auth', 'logged_out'])
  readonly loggedOut!: Observable<boolean>;

  private subscriptions: Subscription[] = [];

  private authStateObserver = this.authenticated.pipe(
    filter((authenticated) => !!authenticated),
    map(() => {
      this.userActions.loadUserContext();
    })
  );

  private bootStateObserver = this.bootState
    .pipe(
      filter((bootState) => bootState.bootCompleted),
      flatMap((bootState) => from(this.router.navigate([bootState.bootReturnUrl])))
    );

  constructor(private bootActions: BootActions,
              private authActions: AuthActions,
              private userActions: UserActions,
              protected translations: TranslateService,
              private localStorage: LocalStorageService,
              private environment: AppEnvService,
              private i18nService: I18NService,
              private router: Router,
              protected modalService: SepModalService,
              private location: Location
  ) {

    this.helpOnLine = false;

    this.subscriptions.push(
      this.bootStateObserver.subscribe(),
      this.authStateObserver.subscribe(),
      this.themeStateObserver.subscribe(),
      this.loggedOut.pipe(
        filter((loggedOut) => true === loggedOut),
        map(() => this.bootActions.startBoot('/login'))
      ).subscribe(),
    );
  }

  ngOnInit(): void {

    this.i18nService.initialize();

    const currentLocationPath = this.location.path();

    this.bootActions.startBoot(currentLocationPath === '/login' ? '/' : currentLocationPath);

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  showHelpOnLine = () => {
    return false;
  };

  openOnlineHelp = () => {
  };

  private applyCustomThemStyles = (css) => {
    const head = document.getElementsByTagName('head')[0];
    const style = document.createElement('style');
    style.type = 'text/css';
    style.appendChild(document.createTextNode(css));
    head.appendChild(style);
  };

  private themeStateObserver = this.themeState.pipe(
    filter(themeState => !!themeState.config),
    map(themeState => this.applyCustomThemStyles(themeState.config.css))
  );
}
