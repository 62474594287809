import {NgModule} from '@angular/core';
import {DasHeaderComponent} from './das-header/das-header.component';
import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {UserService} from '@shared/app/user/service';
import {UserActions} from '@shared/app/user/store/actions';
import {UserEpics} from '@shared/app/user/store/epics';
import {HierarchyService} from '@shared/app/user/hierarchy/api/service';
import {BreadcrumbService} from '@shared/app/common/_services/breadcrumb.service';
import {AppCommonModule} from '@shared/app/common/module';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {CommonModule} from '@angular/common';
import {I18NModule} from '@shared/app/i18n/module';
import {LayoutModule} from '@shared/app/layout/module';
import {RouterModule} from '@angular/router';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {DasFlatCoursesComponent} from './flat-course/das-flat-courses.component';
import {DasBreadcrumbComponent} from './das-breadcrumb/das-breadcrumb.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  declarations: [
    DasHeaderComponent,
    DasFlatCoursesComponent,
    DasBreadcrumbComponent
  ],
  exports: [
    DasHeaderComponent,
    DasFlatCoursesComponent,
    DasBreadcrumbComponent
  ],
  imports: [
    AppCommonModule,
    BsDropdownModule,
    CommonModule,
    I18NModule,
    LayoutModule,
    RouterModule,
    TooltipModule
  ],
  providers: [
    UserService, UserActions, UserEpics, HierarchyService, BreadcrumbService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }]
})

export class DasLayoutModule {
}
