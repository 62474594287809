import {combineReducers} from 'redux';
import {pathsReducer, PathsState} from '../paths/store/reducers';
import {scormUnitReducer, ScormUnitState} from '../scorm/unit/store/reducers';
import {coursesReducer, CoursesState} from '../courses/store/reducers';
import {scormCourseReducer, ScormCourseState} from '../scorm/course/store/reducers';
import {resourcesReducer, ResourcesState} from '@shared/app/lms/resources/store/reducers';
import {lessonsReducer, LessonsState} from '@shared/app/lms/synchronous/store/reducers';

export type LmsState = {
  paths: PathsState,
  courses: CoursesState,
  resources: ResourcesState,
  scormUnit: ScormUnitState,
  scormCourse: ScormCourseState,
  lessons: LessonsState
}
export const lmsReducer = () =>
  combineReducers({
    paths: pathsReducer(),
    courses: coursesReducer(),
    resources: resourcesReducer(),
    scormUnit: scormUnitReducer(),
    scormCourse: scormCourseReducer(),
    lessons: lessonsReducer()
  });

