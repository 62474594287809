<app-banner *ngIf="areas"
	[display]="'thin'"
	[title]="bannerTitle"
	[description]="bannerDescription"
	[backgroundImage]="'assets/img/course-banner.jpg'"
	[colorSchema]="'light'"
></app-banner>

<div class="catalog-container page-template-thin">

	<div *ngIf="view == 'AREASLIST' && areas" class="row">
		<div class="col col-lg-6 col-md-12 area-item" *ngFor="let area of areas" (click)="selectArea(area)">
			<div class="col col-sm-12 area-content" [style.background-image]="area.image">
				<div class="area-title" [innerHtml]="area.name"></div>
			</div>
		</div>
	</div>

	<div *ngIf="view == 'AREADETAILS'">
		<a routerLink="/catalog" class="btn btn-info btn-back"><i class="fa fa-arrow-left"></i> <span translate>back</span></a>

		<div class="row area-item">
			<div class="col col-sm-12 area-content" [style.background-image]="areaSelected.image">
				<div class="area-title" [innerHtml]="areaSelected.name"></div>
			</div>
		</div>
	</div>

	<div *ngIf="view == 'AREADETAILS' && paths && paths.length > 0">

		<div class="row path-item" *ngFor="let path of paths">
			<div class="col-sm-2 image-container clickable" [style.background-image]="path.imageUrl" (click)="openPath(path)">
			</div>
			<div class="col-sm-8 details-container">
				<div class="path-title size-1 brand-color clickable" (click)="openPath(path)">{{path.title}}</div>
				<div [innerHTML]="path.description"></div>
				<div class="details-bottom">
					<a (click)="openPath(path)" class="catalogdetails clickable"><i class="fa fa-plus"></i> <span translate>Details</span></a>
				</div>
			</div>
			<div class="col-sm-2 buttons-container">
				<button class="btn btn-primary clickable" *ngIf="path.enrolled !== true" (click)="enrollToPath(path)"><span translate>enroll</span>&nbsp;<i class="fa fa-user-plus"></i></button>
				<span *ngIf="path.enrolled === true" class="course-enrolled"><i class="fa fa-check"></i> <span translate>enrolled</span></span>
			</div>
		</div>
	</div>

	<div *ngIf="view == 'AREADETAILS' && paths && paths.length == 0">
		<div class="alert alert-info" translate>No learning paths in this area</div>
	</div>

</div>

<div *ngIf="areas && areas.length == 0" class="alert alert-info"><span translate>No areas available </span><i class="fa fa-frown-o" aria-hidden="true"></i></div>
