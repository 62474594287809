import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';

export enum BreadcrumbLevel {
    root = 0,
    first = 1,
    second = 2,
    third = 3
}

export interface SepBreadcrumb {
  label: string;
  params: Params;
  url: string;
  level: BreadcrumbLevel;
  isActive: boolean;
}

class BreadcrumbObject implements SepBreadcrumb {
    label: string;
    params: Params;
    url: string;
    level: BreadcrumbLevel;
    isActive: boolean;

    constructor(label: string, params: Params, url: string, level: BreadcrumbLevel, isActive: boolean) {
        this.label = label;
        this.params = params;
        this.url = url;
        this.level = level;
        this.isActive = isActive;
    }
}


export class BreadCrumbFactory {
    createBreadCrumb(label: string, params: Params, url: string, level: BreadcrumbLevel, isActive?: boolean): BreadcrumbObject {
        if (!isActive) {
            isActive = false;
        }
        return new BreadcrumbObject(label, params, url, level, isActive);
    }
}

@Injectable()
export class BreadcrumbService {

    private _breadcrumbs: SepBreadcrumb[];
    private _breadcrumbsSource: BehaviorSubject<SepBreadcrumb[]>;
    public breadcrumbs: Observable<SepBreadcrumb[]>;

    constructor() {
      this._breadcrumbs = [];
      this._breadcrumbsSource = new BehaviorSubject([]);
      this.breadcrumbs = this._breadcrumbsSource.asObservable();
      const b: SepBreadcrumb = (new BreadCrumbFactory).createBreadCrumb('Home', null, '/', BreadcrumbLevel.root, true);
      // {level: BreadcrumbLevel.root, label: 'Home', url: '/', params: null};
      this.setBreadcrumb(b);
    }

    setBreadcrumb(b: SepBreadcrumb) {
        if (b.level < this._breadcrumbs.length) {
            this._breadcrumbs.splice(b.level, this._breadcrumbs.length - b.level);
        }
        this._breadcrumbs[b.level] = b;
        this._breadcrumbsSource.next(this._breadcrumbs);
    }

    getBreadcrumbs(): Observable<SepBreadcrumb[]> {
      return this.breadcrumbs;
    }
}
