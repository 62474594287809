import {Action} from 'redux';
import {PASSWORD_CHANGE_STATE, PasswordFailError, UserPreferences, UserState} from '../model';
import {UserAction, UserActions} from './actions';
import {UserService} from '../service';


export enum PASSWORD_RECOVER_STATE {
  none,
  recovering,
  recovered,
  failed
}


const INITIAL_STATE: UserState = {
    loading: false,
    loadFailed: false,
    passwordRecoverState: PASSWORD_RECOVER_STATE.none,
    passwordChangeFailureMessage: undefined,
    passwordChangeState: {status: PASSWORD_CHANGE_STATE.none}
  }
;


export const userReducer = () => (state: UserState = INITIAL_STATE, action: Action): UserState => {
  switch (action.type) {
    case UserActions.USER_CONTEXT_LOAD_STARTED:
      return {
        ...state,
        details: undefined,
        stats: undefined,
        fields: undefined,
        loading: true,
        loadFailed: false,
        lessonsSessions: undefined
      };
      break;
    case UserActions.USER_CONTEXT_LOAD_SUCCEEDED:
      return {
        ...state,
        loading: false,
        loadFailed: false,
        ...(action as UserAction<UserState>).payload,
      };
      break;
    case UserActions.USER_CONTEXT_LOAD_FAILED:
      return {
        ...state,
        loading: false,
        loadFailed: true,
      };
      break;

    case UserActions.USER_PROFILE_IMAGE_LOADED:
      return {
        ...state,
        details: {...state.details, imageUrl: (action as UserAction<string | ArrayBuffer>).payload}
      };
      break;
    case UserActions.USER_PROFILE_IMAGE_DELETED:
      return {
        ...state,
        details: {...state.details, imageUrl: UserService.defaultProfileImageUrl}
      };
      break;
    case UserActions.USER_PREFERENCES_UPDATED:
      return {
        ...state,
        details: {...state.details, preferences: {...(action as UserAction<UserPreferences>).payload}}
      };
      break;
    case UserActions.USER_HIERARCHY_SWITCH:
      return {
        ...state,
        loading: false,
        ...(action as UserAction<UserState>).payload
      };
      break;
    case UserActions.USER_PASSWORD_RECOVERING:
      return {
        ...state,
        passwordRecoverState: PASSWORD_RECOVER_STATE.recovering
      };
      break;
    case UserActions.USER_PASSWORD_RECOVERED:
      return {
        ...state,
        passwordRecoverState: PASSWORD_RECOVER_STATE.recovered
      };
      break;
    case UserActions.USER_PASSWORD_RECOVER_FAILED:
      return {
        ...state,
        passwordRecoverState: PASSWORD_RECOVER_STATE.failed
      };
      break;
    case UserActions.USER_PRIVACY_ACCEPTED: {
      return {
        ...state,
        details: {...state.details, privacyChecked: true}
      };
    }
      break;
    case UserActions.USER_PASSWORD_CHANGED_SUCCESS:
      return {
        ...state,
        passwordChangeState: {status: PASSWORD_CHANGE_STATE.changed, message: ''}
      };
      break;
    case UserActions.USER_PASSWORD_CHANGED_FAIL:
      return {
        ...state,
        passwordChangeState: {
          status: PASSWORD_CHANGE_STATE.failed,
          message: (action as UserAction<PasswordFailError>).payload.error_description
        }
      };

      break;
    case UserActions.USER_PASSWORD_RESET:
      return {
        ...state,
        passwordChangeState: {
          status: PASSWORD_CHANGE_STATE.none,
          message: ''
        }
      };
      break;

    case UserActions.USER_STATS_LOAD_STARTED:
      return {
        ...state,
        stats: undefined,
        lessonsSessions: undefined
      };
      break;
    case UserActions.USER_STATS_LOAD_SUCCEEDED:
      return {
        ...state,
        ...(action as UserAction<UserState>).payload,
      };
      break;
    case UserActions.USER_STATS_LOAD_FAILED:
      return {
        ...state,
        loading: false,
        loadFailed: true,
      };
      break;
  }
  return state;
};

