import {Action} from "redux";
import {LmsPathsActions, PathsAction} from "./actions";
import {LmsPath} from "./model";


export interface PathsState {
  loadStarted: boolean,
  loadFilter: string
  loadFailed: boolean,
  loadCompleted: boolean,
  loadError: any,
  openError: any,
  opening: boolean,
  list: Array<LmsPath>,
  opened: LmsPath
}

const INITIAL_STATE: PathsState = {
  loadStarted: false,
  loadFailed: false,
  loadFilter: undefined,
  loadCompleted: false,
  loadError: undefined,
  list: undefined,
  openError: undefined,
  opening: false,
  opened: undefined
};

export const pathsReducer = () => (state: PathsState = INITIAL_STATE, action: Action): PathsState => {
  switch (action.type) {
    case LmsPathsActions.PATHS_LOAD:
      return {
        ...state,
        loadFilter: (action as PathsAction<string>).payload,
        opened: undefined,
        opening: false,
        openError: undefined
      };
    case LmsPathsActions.PATHS_LOAD_STARTED:
      return {
        ...state,
        loadStarted: true,
      };
    case LmsPathsActions.PATHS_LOAD_FAILED:
      return {
        ...state,
        loadStarted: false,
        loadFailed: true,
        loadError: {...(action as PathsAction<any>).payload}
      };
    case LmsPathsActions.PATHS_LOAD_SUCCEED:
      return {
        ...state,
        loadCompleted: true,
        loadStarted: false,
        loadFailed: false,
        list: (action as PathsAction<Array<LmsPath>>).payload
      };
    case LmsPathsActions.PATH_OPENING:
      return {
        ...state,
        opening: true
      }
    case LmsPathsActions.PATH_OPEN_FAILED:
      return {
        ...state,
        opening: false,
        openError: {...(action as PathsAction<any>).payload}
      }
    case LmsPathsActions.PATH_OPENED:
      return {
        ...state,
        opening: false,
        opened: (action as PathsAction<LmsPath>).payload
      }
  }
  return state;
};

