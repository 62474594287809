import {CourseResourceReport, DoughnutChart} from '../_models/course-resource-report';
import {ReportService} from '../_services/report.service';
import {Component, OnInit, Pipe, PipeTransform, TemplateRef, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {Theme} from '../../theme/model';
import {
  BreadCrumbFactory,
  BreadcrumbLevel,
  BreadcrumbService,
  SepBreadcrumb
} from '../../common/_services/breadcrumb.service';
import {forkJoin, Observable, of, timer, zip} from 'rxjs';
import {UIMessage} from '../../model';
import {select} from '@angular-redux/store';
import {ThemeState} from '../../theme/store/reducers';
import {filter, map, mergeMap} from 'rxjs/operators';
import {CourseReport} from '@shared/app/report/_models/course-report';
import {animate, query, stagger, state, style, transition, trigger} from '@angular/animations';
import * as fileSaver from 'file-saver';
import {ResourceService} from '@shared/app/lms/resources/resource.service';
import {DomSanitizer} from '@angular/platform-browser';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {NotificationsService} from 'angular2-notifications';
import {ReportModalComponent} from '@shared/app/report/report-modal/report-modal.component';
import {Stats} from '@shared/app/user/stats/model';
import {CourseNamePipe} from '@shared/app/common/_pipes/courseName';
import * as FileSaver from 'file-saver';


@Component({
  selector: 'app-report-user',
  templateUrl: './report-user.component.html',
  styleUrls: ['./report-user.component.scss'],
  providers: [CourseNamePipe],
  animations: [
    trigger('pageAnimations', [
      transition(':enter', [
        query('.path-item', [
          style({opacity: 0, transform: 'translateX(-25px)'}),
          stagger(35, [
            animate('500ms cubic-bezier(0.35, 0, 0.25, 1)',
              style({opacity: 1, transform: 'none'}))
          ])
        ])
      ])
    ]),
    trigger('mouseOver', [
      state('over', style({
        background: 'grey', color: 'white'
      })),
      state('out', style({
        transform: 'scale(100%)',
        'z-index': 0,
      })),
      transition('* => over', [
        animate('120ms ease')
      ]),
      transition('* => out', [
        animate('80ms ease')
      ]),
    ]),
  ]
})

export class ReportUserComponent implements OnInit {

  courses: CourseReport[];
  allCourses: CourseReport[];
  filteredCourses: CourseReport[];
  labels: Object;
  bannerTitle: string;
  bannerDescription: string;
  loadingReport: boolean;
  loadingStats: boolean;
  loadingStatsYear: boolean;
  message: UIMessage;
  certModalRef: BsModalRef;
  certErrorMessage: String;
  status: string;
  title: string;
  yearReport: string = new Date().getFullYear().toString();
  yearStats: string = new Date().getFullYear().toString();
  descending: boolean;
  years: Array<number>;
  visibleYears = 10;
  currentYear = new Date().getFullYear();
  userStats: Stats;
  userStatsByYear: Stats;
  char: DoughnutChart;
  charByYear: DoughnutChart;

  colors = [
    {
      backgroundColor: ['rgb(23, 162, 184)', 'rgb(32, 201, 151)', 'rgb(11, 66, 193)', 'rgb(255, 193, 7)'],
      borderColor: ['rgb(23, 162, 184)', 'rgb(32, 201, 151)', 'rgb(11, 66, 193)', 'rgb(255, 193, 7)'],
      pointBackgroundColor: 'rgba(77,83,96,1)',
      pointBorderColor: '#a1a1a1',
      pointHoverBackgroundColor: 'rgba(77,83,96,1)',
      pointHoverBorderColor: 'rgba(77,83,96,1)',
      borderDashOffset: 1,
      circular: false
    }
  ];

  @select('theme')
  private readonly theme: Observable<ThemeState>;

  constructor(
    private reportService: ReportService,
    private bcService: BreadcrumbService,
    private router: Router,
    private route: ActivatedRoute,
    private courseNamePipe: CourseNamePipe,
    private translateService: TranslateService,
    private resourceService: ResourceService,
    private domSanitizer: DomSanitizer,
    private modalService: BsModalService,
    private notificationSrv: NotificationsService
  ) {
  }

  ngOnInit() {


    this.labels = {};
    this.status = 'COMPLETED';
    this.yearReport = '2024';
    this.yearStats = '2024';
    this.descending = true;
    this.years = [];
    this.loadingStatsYear = true;
    this.loadingStats = true;
    for (let i = 0; i <= this.visibleYears; i++) {
      this.years.push(new Date().getFullYear() - i);
    }
    forkJoin(
      this.translateService.get('First'),
      this.translateService.get('Last'),
      this.translateService.get('Previous'),
      this.translateService.get('Next'),
      this.translateService.get('My dashboard'),
      // tslint:disable-next-line:max-line-length
      this.translateService.get('Visualize your learning journey and the status of all your courses.')
    ).subscribe(t => {
      this.labels['reportuser'] = t[4];
      this.labels['first'] = t[0];
      this.labels['last'] = t[1];
      this.labels['previous'] = t[2];
      this.labels['next'] = t[3];
      this.bannerTitle = t[4];
      this.bannerDescription = t[5];
      // tslint:disable-next-line:max-line-length
      const b: SepBreadcrumb = (new BreadCrumbFactory).createBreadCrumb(this.labels['reportuser'], null, 'report/user', BreadcrumbLevel.first);
      this.bcService.setBreadcrumb(b);
    });
    this.translateService.stream(['mandatory', 'optional']).subscribe(
      translated => {
        this.char = {
          labels: [
            translated['mandatory'],
            translated['optional'],
          ],
          data: [],
          type: 'doughnut'
        };
        this.charByYear = {
          labels: [
            translated['mandatory'],
            translated['optional'],
          ],
          data: [],
          type: 'doughnut'
        };
      },
    );


    this.getUserStats(this.yearStats);
    this.getUserReport();
  }

  getUserReport() {
    this.loadingReport = true;
    this.reportService.getUserReport().subscribe(
      r => {
        this.loadingReport = false;
        const _courses: CourseReport[] = [];
        for (const cObj of r) {
          const c = new CourseReport();
          Object.assign(c, cObj);
          _courses.push(c);

        }
        this.allCourses = _courses.sort((a, b) => {
          return b.date_completed && a.date_completed ? new Date(b.date_completed).getTime() - new Date(a.date_completed).getTime() : 0;
        }).sort((a, b) => {
          return a.state === 'STARTED' ? 1 : 0;
        }).sort((a, b) => {
          return a.state === 'COMPLETED' ? 1 : 0;
        });
        this.filterList(null);
        // this.onStatusChangeReport(null);
        // this.setPage(1);
      },
      err => {
        this.translateService.stream(['Error loading the element']).subscribe(
          t => {
            this.notificationSrv.error(t['Error loading the element'], null, {timeOut: 5000});
          });
        this.loadingReport = false;
      }
    );
  }

  onStatusChangeReport(e) {
    this.filteredCourses = this.allCourses
      .filter(it => {
        return this.title?.length > 0 ? it.course_title.toUpperCase().indexOf(this.title.toUpperCase()) >= 0 : true;
      }).filter(it => {
        return this.status && this.status !== 'All' ? (this.status === 'other' ? (it.state === 'STARTED IN ANOTHER COURSE' || it.state === 'COMPLETED IN ANOTHER PATH') : it.state === this.status) : true;
      }).filter(it => {
        return this.yearReport !== '0' ? new Date(it?.date_completed)?.getFullYear().toString() === this.yearReport : true;
      });

    this.setPage(1);
  }

  onYearChange(e) {
    this.getUserStatsOnlyYear(e.target.value !== '0' ? this.yearReport : null);
    this.filteredCourses = this.allCourses
      .filter(it => {
        return this.title?.length > 0 ? it.course_title.toUpperCase().indexOf(this.title.toUpperCase()) >= 0 : true;
      }).filter(it => {
        return this.status && this.status !== 'All' ? (this.status === 'other' ? (it.state === 'STARTED IN ANOTHER COURSE' || it.state === 'COMPLETED IN ANOTHER PATH') : it.state === this.status) : true;
      }).filter(it => {
        return this.yearReport !== '0' ? new Date(it?.date_completed)?.getFullYear().toString() === this.yearReport : true;
      });

    this.setPage(1);
  }

  getUserStats(year?: string) {
    this.loadingStatsYear = true;
    zip(this.reportService.getStats(), this.reportService.getStats(year)).subscribe(data => {
      this.loadingStats = false;
      this.loadingStatsYear = false;
      this.userStats = this.userStats ? this.userStats : data[0];
      this.userStatsByYear = data[1];
      this.char.data = this.char.data.length > 0 ? this.char.data : [
        data[0].countersCourse.userCourseAssociationNotCompleted.mandatoryTotalCoursesHours / 60,
        data[0].countersCourse.userCourseAssociationNotCompleted.optionalTotalCoursesHours / 60,
      ];
      this.charByYear.data = [
        data[1].countersCourse.userCourseCompleted.mandatoryTotalCoursesHours / 60,
        data[1].countersCourse.userCourseCompleted.optionalTotalCoursesHours / 60,
      ];
    }, error => {
      this.loadingStats = false;
      this.translateService.stream(['Error loading the element']).subscribe(
        t => {
          this.notificationSrv.error(t['Error loading the element'], null, {timeOut: 5000});
        });
    });
  }

  getUserStatsOnlyYear(year?: string) {
    this.loadingStatsYear = true;
    this.reportService.getStats(year).subscribe(
      r => {
        this.loadingStatsYear = false;
        this.userStatsByYear = r;
        this.charByYear.data = [
          r.countersCourse.userCourseCompleted.mandatoryTotalCoursesHours / 60,
          r.countersCourse.userCourseCompleted.optionalTotalCoursesHours / 60,
        ];
      },
      err => {
        this.loadingStats = false;
        this.translateService.stream(['Error loading the element']).subscribe(
          t => {
            this.notificationSrv.error(t['Error loading the element'], null, {timeOut: 5000});
          });
        this.loadingStatsYear = false;
      }
    );
  }

  onStatusChangeStats(e) {
    this.getUserStatsOnlyYear(e.target.value !== '0' ? e.target.value : null);
  }

  setPage(page: number, itemsPerPage: number = 20) {
    const startItem = (page - 1) * itemsPerPage;
    const endItem = page * itemsPerPage;
    if (this.filteredCourses !== undefined) {
      this.courses = this.filteredCourses.slice(startItem, endItem);
    }
  }

  pageChanged(event: PageChangedEvent): void {
    this.setPage(event.page, event.itemsPerPage);
  }

  filterList(value) {
    if (value) {
      this.filteredCourses = this.courseNamePipe.transform(this.allCourses.filter(it => {
        return this.title?.length > 0 ? it.course_title.toUpperCase().indexOf(this.title.toUpperCase()) >= 0 : true;
      }).filter(it => {
        return this.status && this.status !== 'Select' ? (this.status === 'other' ? (it.state === 'STARTED IN ANOTHER COURSE' || it.state === 'COMPLETED IN ANOTHER PATH') : it.state === this.status) : true;
      }).filter(it => {
        return this.yearReport !== '0' ? new Date(it?.date_completed)?.getFullYear().toString() === this.yearReport : true;
      }), value);
    } else {
      this.filteredCourses = this.allCourses.filter(it => {
        return this.title?.length > 0 ? it.course_title.toUpperCase().indexOf(this.title.toUpperCase()) >= 0 : true;
      }).filter(it => {
        return this.status && this.status !== 'Select' ? (this.status === 'other' ? (it.state === 'STARTED IN ANOTHER COURSE' || it.state === 'COMPLETED IN ANOTHER PATH') : it.state === this.status) : true;
      }).filter(it => {
        return this.yearReport !== '0' ? new Date(it?.date_completed)?.getFullYear().toString() === this.yearReport : true;
      });
    }
    this.setPage(1);
  }

  filterListByStatus(value) {
    if (value) {
      this.filteredCourses = this.courseNamePipe.transformByStatus(this.allCourses, value);
    } else {
      this.filteredCourses = this.allCourses;
    }
    this.setPage(1);
  }

  goToCourse(course: CourseReport) {
    if (course.id_course !== undefined) {
      this.router.navigate(['path', course.id_path, 'course', course.id_course]);
    }
  }

  sortBy(property: string) {
    this.filteredCourses.sort((a, b) => {
      if (a[property] < b[property]) {
        return this.descending === true ? -1 : 1;
      }
      if (a[property] > b[property]) {
        return this.descending === true ? 1 : -1;
      }
      return 0;
    });
    this.descending = !this.descending;
    this.setPage(1);
  }

  viewCertificate(course: CourseReport) {
    this.resourceService.getCertificate(course.id_course).subscribe(
      r => {
        const objectUrl = window.URL.createObjectURL(r);
        const certificateSrc = this.domSanitizer.bypassSecurityTrustResourceUrl(objectUrl);
        console.log(certificateSrc);
        this.certModalRef = this.modalService.show(ReportModalComponent, {
          backdrop: 'static',
          keyboard: false,
          class: 'modal-lg modal-unit'
        });
        this.certModalRef.content.certificateSrc = certificateSrc;
        this.certModalRef.content.course = course;
      },
      err => {
        this.translateService.stream(['Error loading the element', 'Course not completed']).subscribe(
          t => {
            this.notificationSrv.error(t['Error loading the element'], null, {timeOut: 5000});

            if (err.error && err.message) {
              if (err.status === 400) {
                this.notificationSrv.error(t['Course not completed'], null, {timeOut: 5000});
              }
            }
          });
      }
    );
  }
}

