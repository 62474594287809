import {Injectable} from "@angular/core";
import {ResourceService} from "../../../resources/resource.service";

@Injectable()
export class ScormCourseService {

  constructor(private lmsResourceService: ResourceService) {
  }

  loadResource = (resourceId: number) => this.lmsResourceService.getResource(resourceId)

}
