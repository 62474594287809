<div class="modal-header align-content-center text-center flow">
  <div class='col-12 modal-title text-center text-danger'>
    <button type="button" class="close pull-right" aria-label="Close" (click)="onCancel()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h3 class='modal-title text-center text-danger' translate>Warning</h3>
  </div>
</div>
<div class="modal-body">
  <span *ngIf="identityVdcType!= null" [ngSwitch]="identityVdcType">
    <div *ngSwitchCase="'TEAMS'">
      <p style="padding: 10px;" class="text-center">Per permettere alla piattaforma di rilevare la tua presenza e quindi per beneficiare
        della certificazione delle ore formative è indispensabile che tu inserisca la tua username abbinata alla tua utenza su SEP nel campo <br>
        <b>“TYPE YOUR NAME / SCRIVI IL TUO NOME” </b><br>
        nella schermata di accesso alla meeting Teams.<br><br><img class="img-thumbnail col-10" src="assets/img/teams-guest.jpg" alt="" title="">
      </p>
      <p class='col-12 text-center font-weight-bold'>Il mancato inserimento renderà IMPOSSIBILE certificare le ore formative. <br>Qui di seguito la tua username</p>
      <hr>
      <div class="container">
        <div class="row justify-content-md-center">
            <div class="col-5 co-a">
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <button tooltip="COPIATO"
                          triggers="click" #pop="bs-tooltip" class="btn btn-sm btn-primary" type="button" ngxClipboard
                          (cbOnSuccess)="copy($event)" [cbContent]="identityVdcUser" translate>COPIA</button>
                </div>
                <br>
                <input type="text" class="form-control text-center" value="{{identityVdcUser}}" disabled placeholder=""
                       aria-label="" aria-describedby="basic-addon1">
              </div>
            </div>
          </div>
      </div>
      <hr>
      <div class='col-12 text-center'>
        <button class="btn btn-success" (click)="onConfirm()"translate>JOIN THE MEETING</button>
      </div>
    </div>
    <div *ngSwitchCase="'CONNECT'">
      Per accedere su connect devi
    </div>
    <div *ngSwitchCase="'ZOOM'">
      Zoom
    </div>
  </span>
</div>
