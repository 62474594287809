<div *ngIf="(userState | async)" class="home-container page-template-wide" [style.background-image]="bgImage">


    <div *ngIf="(themeState | async) as theme" class="home-contents-wrapper">
        <div class="home-title-container">
            <div class="home-title" [innerHtml]="theme.config.homeTitle | safeHtml"></div>
            <div class="home-subtitle" [innerHtml]="theme.config.homeSubtitle | safeHtml"></div>
        </div>
        <div class="home-menu-container" >
            <div >
                <app-home-stats *appUserAuths="['SEP_USER_COURSES_READ', 'SEP_USER_PATHS_READ']" class="row"></app-home-stats>
            </div>
            <div >
                <app-custom-message></app-custom-message>
            </div>
        </div>
    </div>


</div>
