import {NgModule} from '@angular/core';
import {AppModule} from '@shared/app/lms.module';
import {DasAppComponent} from './component';
import { das, ThemeModule} from './theme';
import {DasLmsModule} from './lms/das-lms.module';
import {DasLayoutModule} from './layout/das-layout.module';
import {RoutingModule} from './routing';

@NgModule({
  declarations: [
    DasAppComponent
  ],
  imports: [
    AppModule,
    RoutingModule,
    DasLmsModule,
    DasLayoutModule,
    ThemeModule.forRoot({
      themes: [das],
      active: 'stroili'
    })
  ],
  providers: [],
  bootstrap: [DasAppComponent]
})
export class IcgebAppModule {
}
